const MathEx = {
  round: (val, precision) => {
    precision = precision || 0;
    let prec = Math.pow(10, precision);
    return Math.round(val * prec) / prec;
  },
  randrande: (min, max) => {
    if (max === undefined) {
      max = min;
      min = 0;
    }
    return Math.floor(Math.random() * (max - min) + min);
  },
};

export default MathEx;
