import { SlidrBlob, sel as _sel } from './SlidrBlob';
import Util from './Util';

// disable Slidr autodiscover (see SlidrBlob autodiscover at the bottom)
SlidrBlob.autodiscover = false;

const getBEMPageNav = Util.getBEMFn('page-nav');
const sel = Object.assign({}, _sel, {
  pageNav: {
    $: getBEMPageNav(),
    item: getBEMPageNav('item'),
    negative: getBEMPageNav(null, 'negative'),
  },
});
const cls = Util.selToCls(sel);

class SlidrSimons extends SlidrBlob {
  init() {
    this.el.pageNav = {
      $: $(sel.pageNav.$),
      item: $(sel.pageNav.item),
    };

    super.init();
  }

  showSlide(val) {
    if (super.showSlide(val)) {
      this.el.pageNav.$.toggleClass(cls.pageNav.negative, this.activeSlide.negative);
      return true;
    }
    return false;
  }

  static _autodiscover() {
    $(sel.$).each((index, el) => new SlidrSimons($(el)));
  }
}

SlidrSimons.autodiscover = true;

$(() => {
  if (SlidrSimons.autodiscover) SlidrSimons._autodiscover();
});
