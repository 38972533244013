import { Slidr, sel as _sel } from './Slidr';
import Util from './Util';

// keep synchronized with css file
const BlobWidth = 480;

// disable Slidr autodiscover (see SlidrBlob autodiscover at the bottom)
Slidr.autodiscover = false;

const getBEM = Util.getBEM;
const getBEMBlob = Util.getBEMFn('blob');
const sel = Object.assign({}, _sel, {
  slide: {
    attrId: 'data-slide-id',
  },
  blob: {
    $: getBEMBlob(),
    active: getBEMBlob(null, 'active'),
    hover: getBEMBlob(null, 'hover'),
    in: getBEMBlob(null, 'in'),
    out: getBEMBlob(null, 'out'),

    bubble: getBEMBlob('bubble'),

    placeholder: getBEM('blob-placeholder'),
  },
});
const cls = Util.selToCls(sel);

class SlidrBlob extends Slidr {
  init() {
    this.el.blob = {
      $: $(sel.blob.$),
      placeholder: $(sel.blob.placeholder),
      bubble: $(sel.blob.bubble),
    };

    super.init();

    this.el.blob.$.on('mousewheel', (ev) => this.activeSlide.$el.trigger(ev))
      .on('touchstart', (ev) => this.activeSlide.$el.trigger(ev))
      .on('touchend', (ev) => this.activeSlide.$el.trigger(ev));

    $(window).on('resize', this.updateBlobPosition.bind(this));
  }

  showSlide(val) {
    // switch slides
    if (super.showSlide(val)) {
      this.updateBlobPosition();
      this.el.blob.$.attr(sel.slide.attrId, this.el.slide.eq(val).prop('id'));
      return true;
    }
    return false;
  }

  updateBlobPosition() {
    let $slide = this.activeSlide.$el;
    let $blob = this.el.blob.$;
    let $placeholder = $slide.find(sel.blob.placeholder);

    if ($placeholder.length) {
      let pos = $placeholder.position(),
        posParent = $placeholder.parent().position(),
        offset = $placeholder.offset(),
        offsetLeft = offset.left,
        offsetTop = pos.top + posParent.top,
        windowWidth = $(window).outerWidth(),
        minScale = Math.min(1, $placeholder.width() / BlobWidth),
        maxScale = 1.3,
        scale = Math.min(maxScale, Math.max(minScale, (windowWidth * 0.5) / BlobWidth));

      $blob
        .addClass(cls.blob.active)
        .css('transform', `translate(${offsetLeft}px, ${offsetTop}px) scale(${scale}`);
    } else {
      $blob.removeClass(cls.blob.active).removeAttr('style');
    }
  }

  static _autodiscover() {
    $(sel.$).each((index, el) => new SlidrBlob($(el)));
  }
}

SlidrBlob.autodiscover = true;

$(() => {
  if (SlidrBlob.autodiscover) SlidrBlob._autodiscover();
});

export { SlidrBlob, sel };
