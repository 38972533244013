import config from '../../config/index';
import Cookie from '../Cookie';

const endpoints = {
  login: {
    url: '/api/user/login',
    method: 'post',
  },
  getSnippet: {
    url: '/api/snippet/news',
    method: 'get',
  },
  putSnippet: {
    url: '/api/snippet/news',
    method: 'put',
  },
  postSnippet: {
    url: '/api/snippet',
    method: 'post',
  },
};

let instance = null;

class Api {
  static getInstance() {
    if (!instance) instance = new Api();
    return instance;
  }

  constructor() {
    this.session = Cookie.get('session');
  }

  login(email, pw) {
    return new Promise((resolve, reject) => {
      this.request('login', { email, password: pw })
        .then((data) => {
          this.session = data.token;
          Cookie.set('session', data.token);
          resolve(data);
        })
        .catch(reject);
    });
  }

  isLoggedIn() {
    return !!this.session;
  }

  getSnippet() {
    return this.request('getSnippet');
  }

  updateSnippet(snippet) {
    return this.request('putSnippet', snippet);
  }

  createSnippet() {
    return this.request('postSnippet', { name: 'news', content: '' });
  }

  request(endpoint, data) {
    let ep = endpoints[endpoint];

    // throw if endpoint doesn't exist
    if (!ep) throw new Error(`There is no endpoint named "${endpoint}"`);

    // conditionally add session
    let headers = null;
    if (this.session) headers = { auth: 'Token ' + this.session.token };

    return $.ajax({
      url: ep.url,
      method: ep.method,
      contentType: 'application/json',
      data: JSON.stringify(data),
      headers,
    });
  }
}

export default {
  getInstance: Api.getInstance,
};
