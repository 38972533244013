import Util from './Util';

const config = {
  plcStart: '{{',
  plcEnd: '}}',
};
const templates = {};

function add(name, tpl) {
  let offset = 0;
  let index = 0;
  let plcs = [];

  do {
    index = tpl.indexOf(config.plcStart, offset);
    let indexEnd = tpl.indexOf(config.plcEnd, index);
    offset = indexEnd + config.plcEnd.length;

    if (index < 0 || indexEnd < 0) break;

    let plcName = tpl.substring(index + config.plcStart.length, indexEnd);
    if (plcs.indexOf(plcName < 0)) plcs.push(plcName);
  } while (index > -1);

  templates[name] = {
    tpl,
    plcs,
  };
}

function fill(name, data) {
  if (!templates[name] || !templates[name].tpl) return;
  let tpl = templates[name];

  return tpl.plcs.reduce((html, plc) => {
    let searchVal = new RegExp(config.plcStart + plc + config.plcEnd, 'gi'),
      replaceVal = Util.isNull(data[plc]) ? '' : data[plc];
    return html.replace(searchVal, replaceVal);
  }, tpl.tpl);
}

export default {
  add,
  fill,
};
