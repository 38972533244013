'use strict';
import MathEx from './MathEx';

const isNull = (val) => val === null || val === undefined;
const isObject = (val) => typeof val === 'object' && val !== null;
const isArray = (val) => Array.isArray(val);
const isFunction = (val) => typeof val === 'function';
const isString = (val) => typeof val === 'string';
const isNumber = (val) => !isNaN(parseFloat(val)) && isFinite(val);
const isInt = (val) => isNumber(val) && val === toInt(val);
const isJQuery = (val) => !isNull(val) && val.constructor.name === 'jQuery';

const getBEM = (block, el, mod) => `.${block}` + (el ? `__${el}` : '') + (mod ? `_${mod}` : '');
const getBEMFn = (block) => (el, mod) => getBEM(block, el, mod);
const cls = (sel) => (sel.indexOf('.') === 0 ? sel.substr(1) : sel);

function selToCls(selObj) {
  let clsObj = {};
  Object.keys(selObj).forEach((key) => {
    let val = selObj[key];
    clsObj[key] = isString(val) ? cls(val) : selToCls(selObj[key]);
  });
  return clsObj;
}

function toInt(val) {
  let parsed = parseFloat(val);
  return isNumber(parsed) ? MathEx.round(parsed) : 0;
}

/**
 * Debounce a funciton call by a specific time amound.
 * By debouncing it, the function will only execute when not repeatedly triggered in the specified time interval.
 * @param fn {function} - function to debounce
 * @param time {int} - time interval
 * @returns {function} - debounced function
 */
function debounce(fn, time) {
  let self = null,
    args = null,
    timeout = null;

  if (typeof fn !== 'function') throw new Error('fn must be a function');

  function onCall() {
    self = this;
    args = arguments;

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(invokeFn, time);
  }

  function invokeFn() {
    fn.apply(self, args);
  }

  return onCall;
}

export default {
  isNull,
  isObject,
  isArray,
  isFunction,
  isString,
  isNumber,
  isInt,
  isJQuery,
  getBEM,
  getBEMFn,
  cls,
  selToCls,
  toInt,
  debounce,
};
