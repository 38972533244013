import Api from '../module/admin/Api';

window._VIEW_PRAXIS = () => {
  $(() => {
    const Nodes = {
      news: {
        section: $('#news-section'),
        content: $('#news-content'),
      },
    };

    Api.getInstance()
      .getSnippet()
      .then((snippet) => {
        let content = snippet.content.trim();
        if (!content.length) return;
        Nodes.news.content.html(content);
        Nodes.news.section.find('.news').addClass('news_visible');
      });
  });
};
