import Util from './Util';
import Template from './Template';

// CSS PROPS
// Make sure the values equal the ones in slidr.scss

// animation durations in ms
const FxDuration = 1000; // slide
// below this breakpoint you can scroll inside slides
const BreakpointSlideScroll = 800;

const getBEMSlidr = Util.getBEMFn('slidr');
const sel = {
  $: getBEMSlidr(),
  slide: {
    $: getBEMSlidr('slide'),
    above: getBEMSlidr('slide', 'above'),
    active: getBEMSlidr('slide', 'active'),
    negative: getBEMSlidr('slide', 'negative'),
  },
  scrollable: {
    $: getBEMSlidr('scrollable'),
  },
  content: {
    $: getBEMSlidr('content'),
  },
  dot: {
    $: getBEMSlidr('dot'),
    active: getBEMSlidr('dot', 'active'),
  },
  nav: {
    $: getBEMSlidr('nav'),
    negative: getBEMSlidr('nav', 'negative'),
  },
};
const cls = Util.selToCls(sel);

Template.add('slidr__nav', `<div class="${cls.nav.$}"></div>`);
Template.add(
  'slidr__dot',
  `<div class="${cls.dot.$}" data-title="{{title}}" data-index="{{index}}"></div>`
);

class Slidr {
  constructor($el) {
    this.el = getElements($el);
    this.activeSlide = {
      index: -1,
      id: null,
      $el: null,
    };
    this.switchingSlides = false; // true if slide-switch animation is currently in progress
    this.touchPosition = null; // touch position on touchstart
    this.scrolledToEdge = { top: false, bottom: false }; // scrolled-to edge

    this.init();
  }

  init() {
    this.el.dot = this.createDots();
    this.el.nav = this.createNav();

    // set reversed z-index
    this.el.slide.each((i, el) => $(el).css('z-index', this.el.slide.length - i));

    // check if there is already an active slide. Show first slide by default
    let activeSlide = this.el.slide.filter(sel.slide.active);
    activeSlide = activeSlide.length ? activeSlide.index() : 0;
    this.showSlide(activeSlide);

    this.el.slide.on('touchstart', this.onTouchStart.bind(this));
    this.el.slide.on('touchend', this.onTouchEnd.bind(this));
    this.el.slide.on('mousewheel', this.onMousewheelStart.bind(this));
    this.el.slide.on('mousewheel', Util.debounce(this.onMousewheelEnd.bind(this), 300));
  }

  /**
   * Fill dot template with data for every present slide and add click listener
   * @returns {jQuery} - dots as jQuery
   */
  createDots() {
    return this.el.slide
      .map(
        (index, el) =>
          $(
            Template.fill('slidr__dot', {
              title: $(el).data('title'),
              index,
            })
          )[0]
      )
      .on('click', this.onDotClick.bind(this));
  }

  /**
   * Fill nav template with dots and add it to slidr element
   * @returns {jQuery} - nav as jQuery
   */
  createNav() {
    let $nav = $(Template.fill('slidr__nav')); // create nav
    this.el.dot.each((i, el) => $nav.append(el)); // append dots
    return $nav.appendTo(this.el.$); // append to slidr element
  }

  /**
   * @param val {int|string} - Either the slide's index or data-slide-id attribute
   * @returns {boolean} - true if actually switched slides
   */
  showSlide(val) {
    if (this.switchingSlides) return false; // don't switch if already in progress

    // normalize param
    let index, id, $el, negative;
    if (Util.isString(val)) {
      id = val;
      $el = this.el.slide.filter('#' + id);
      index = $el.index();
    } else if (Util.isInt(val)) {
      index = val;
      $el = this.el.slide.eq(index);
      id = $el.prop('id');
    } else return false; // don't switch if parameter has wrong format
    negative = $el.hasClass(cls.slide.negative);

    if (this.activeSlide.index === index) return false; // don't switch if desired slide is already active
    if (!$el || !$el.length || index < 0) return false; // don't switch if slide doesn't exist

    this.switchingSlides = true; // block slide switching during animation
    this.activeSlide = { id, index, $el, negative };

    // reset slidr deck
    this.el.slide.removeClass(cls.slide.above + ' ' + cls.slide.active);

    // add active and above classes to slides
    this.el.slide.each((i, el) => {
      let $el = $(el);
      if (i < index) $el.addClass(cls.slide.above);
      else if (i === index) {
        $el.addClass(cls.slide.active); // add active class
        $el.find(sel.scrollable.$).scrollTop(0); // scroll content to top
      }
    });

    // handle dots
    this.el.dot.removeClass(cls.dot.active);
    $(`${sel.dot.$}[data-index="${index}"]`).addClass(cls.dot.active);

    // set nav negative
    this.el.nav.toggleClass(cls.nav.negative, negative);

    // re-enable slide-switching after animation
    setTimeout(() => this.onSlideComplete({ currentTarget: this.activeSlide.$el }), FxDuration);

    return true;
  }

  onSlideComplete(ev) {
    this.setScrolledToEdge(ev);
    this.switchingSlides = false;
  }

  onDotClick(ev) {
    this.showSlide($(ev.currentTarget).data('index'));
    $(ev.currentTarget).trigger('mouseleave');
    document.activeElement.blur();
    setTimeout(() => null, 500);
  }

  onTouchStart(ev) {
    console.log('touchstart');
    this.touchPosition = ev.originalEvent.touches[0].clientY;
  }

  onTouchEnd(ev) {
    console.log('touchend');
    let touchEnd = ev.originalEvent.changedTouches[0].clientY,
      touchBuffer = 5,
      dir = 0;

    if (this.touchPosition > touchEnd + touchBuffer) dir = 1; // scrolled down
    else if (this.touchPosition < touchEnd - touchBuffer) dir = -1; // scrolled up
    if (dir) this.checkForSlideSwitch(ev, dir); // if scrolled at all -> check for switch

    // update scrolledToEdge
    this.setScrolledToEdge(ev);
  }

  onMousewheelStart(ev) {
    this.checkForSlideSwitch(ev, ev.originalEvent.wheelDelta > 0 ? -1 : 1);
  }

  onMousewheelEnd(ev) {
    this.setScrolledToEdge(ev);
  }

  checkForSlideSwitch(ev, dir) {
    if (this.switchingSlides) return;

    // don't switch slides and just scroll if
    if (
      $(window).width() < BreakpointSlideScroll && // it's a mobile device and
      !(
        (this.scrolledToEdge.top && dir < 0) || // not (started scrolling on top edge and scrolled up
        (this.scrolledToEdge.bottom && dir > 0)
      )
    )
      return; // or started scrolling on bottom edge and scrolled down)

    // switch slides
    let nextIndex = this.activeSlide.index + dir;
    if (nextIndex < 0) return;

    this.showSlide(nextIndex);
  }

  static _autodiscover() {
    $(sel.$).each((index, el) => new Slidr($(el)));
  }

  /**
   * Must be called on a .slidr__slide element as ev.currentTarget
   * @param ev
   * @returns {null|string}
   */
  setScrolledToEdge(ev) {
    let $target = $(ev.currentTarget),
      $scrollable = $target.find(sel.scrollable.$),
      $content = $target.find(sel.content.$),
      scrollTop = $scrollable.scrollTop();

    if (!$scrollable || !$scrollable.length)
      this.scrolledToEdge.top = this.scrolledToEdge.bottom = true;
    else {
      this.scrolledToEdge.top = scrollTop <= 0;
      this.scrolledToEdge.bottom =
        $content.outerHeight() - (scrollTop + $scrollable.outerHeight()) <= 0;
    }
  }
}

Slidr.autodiscover = true;

// ---- PRIVATE METHODS ----
function getElements($el) {
  return {
    $: $el,
    slide: $el.find(sel.slide.$),
    nav: $el.find(sel.nav.$),
    dot: $el.find(sel.dot.$),
  };
}

$(() => {
  if (Slidr.autodiscover) Slidr._autodiscover();
});

export { Slidr, sel };
