let cookies = document.cookie.split(';').reduce((acc, cookie) => {
  let split = cookie.trim().split('=');
  acc[split[0]] = split[1];
  return acc;
}, {});

function get(name) {
  return cookies[name];
}

function set(name, val) {
  cookies[name] = val;
  document.cookie = getCookieStr(name, val);
}

function remove(name) {
  if (cookie[name]) {
    delete cookie[name];
    document.cookie = getCookieStr(name, '');
  }
}

function getCookieStr(name, val) {
  return `${name}=${val}`;
}

export default {
  get,
  set,
  remove,
};
